import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

import React from 'react';
import { Spinner } from 'components/Spinner';
import { aboutMeta, careersMeta, computerMeta, homeMeta, maritimeMeta, mobileMeta, otherMeta, platformMeta, policyMeta } from 'constants/pages';

const Home = lazy(() => import('modules/Home'));
const Platform = lazy(() => import('modules/Platform'));
const Policy = lazy(() => import('modules/Policy'));
const Company = lazy(() => import('modules/Company'));
const Careers = lazy(() => import('modules/Careers'));
const Maritime = lazy(() => import('modules/Industries/Maritime'));
const MachineVision = lazy(() => import('modules/Industries/MachineVision'));
const MobileMachines = lazy(() => import('modules/Industries/MobileMachines'));
const Others = lazy(() => import('modules/Industries/Others'));
const NotFound = lazy(() => import('components/404'));

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={homeMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <Home {...homeMeta} />
            </React.Suspense>
          }
        />
        <Route
          path={platformMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <Platform {...platformMeta} />
            </React.Suspense>
          }
        />
        <Route
          path={policyMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <Policy {...policyMeta} />
            </React.Suspense>
          }
        />
        <Route
          path={aboutMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <Company {...aboutMeta} />
            </React.Suspense>
          }
        />
        <Route
          path={careersMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <Careers {...careersMeta} />
            </React.Suspense>
          }
        />
        <Route
          path={maritimeMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <Maritime {...maritimeMeta} />
            </React.Suspense>
          }
        />
        <Route
          path={computerMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <MachineVision {...computerMeta} />
            </React.Suspense>
          }
        />
        <Route
          path={mobileMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <MobileMachines {...mobileMeta} />
            </React.Suspense>
          }
        />
        <Route
          path={otherMeta.url}
          element={
            <React.Suspense fallback={<Spinner />}>
              <Others {...otherMeta} />
            </React.Suspense>
          }
        />
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Spinner />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
