import { css } from '@mui/material';

import { colors } from './colors';
import { devices } from './breakpoints';

export const dotMixin = css`
  &:after {
    display: none;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: -17px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background-color: ${colors.blue};

    @media (${devices.md}) {
      display: block;
    }
  }
`;
