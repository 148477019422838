import React, { PropsWithChildren } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { theme, GlobalStyles } from './styles';
import { ScrollContextProvider } from './context/ScrollContext';

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <ScrollContextProvider>{children}</ScrollContextProvider>
    </ThemeProvider>
  );
};
