import { styled } from '@mui/material';
import { colors } from 'styles';

export const SpinnerWrapper = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SpinnerCircle = styled('div')`
  position: fixed;
  margin: auto;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%,${colors.blue});
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
  animation: spinner 1s infinite linear;

  @keyframes spinner {
    to {
       transform: rotate(1turn);
    }
 }
`