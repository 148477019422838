import {
  colors as muiColors,
  PaletteOptions,
  createTheme,
  BreakpointsOptions,
  Theme,
} from '@mui/material';

import { breakpoints as breakpointValues } from './breakpoints';
import { colors } from './colors';

const breakpoints: BreakpointsOptions = {
  values: breakpointValues,
};

const palette: PaletteOptions = {
  primary: {
    main: colors.gray,
  },
  secondary: {
    main: colors.blue,
  },
  error: {
    main: muiColors.red.A200,
  },
};

export const theme: Theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  breakpoints,
  palette,
});
