import { createGlobalStyle } from 'styled-components';

import { colors } from './colors';
import { animations } from './animations';

export const GlobalStyles = createGlobalStyle`
  html {
    overflow-x: hidden;
  }

  .hidden {
    display: none;
  }

  .layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .has-align-center {
    text-align: center;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  body {
    font-size: 16px;
    color: ${colors.mainGray};
    line-height: 18px;
    overflow-x: hidden;

    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${colors.blue};
      position: relative;
      margin: 0;
    }
  }

  ${animations}
`;
