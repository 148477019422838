import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';

export const ScrollContext = createContext(false);

const checkIfScrolled = (): boolean => {
  return window.scrollY > 20;
};

export const ScrollContextProvider = ({ children }: PropsWithChildren) => {
  const [isScrolled, setIsScrolled] = useState(checkIfScrolled);

  const onScroll = useCallback(() => {
    setIsScrolled(checkIfScrolled);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return <ScrollContext.Provider value={isScrolled}>{children}</ScrollContext.Provider>;
};
