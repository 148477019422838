type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

export const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
  xxxl: 2560,
};

const getMinWidthMediaQuery = (breakpoint: number) => {
  return `min-width: ${breakpoint}px`;
};

export const devices: Record<Breakpoint, string> = {
  xs: getMinWidthMediaQuery(0),
  sm: getMinWidthMediaQuery(breakpoints.sm),
  md: getMinWidthMediaQuery(breakpoints.md),
  lg: getMinWidthMediaQuery(breakpoints.lg),
  xl: getMinWidthMediaQuery(breakpoints.xl),
  xxl: getMinWidthMediaQuery(breakpoints.xxl),
  xxxl: getMinWidthMediaQuery(breakpoints.xxxl),
};
